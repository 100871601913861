import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom';
import axios from 'axios';


const RedirectPage = () => {

    const { id } = useParams();


    useEffect(() => {

        axios.get(`http://142.132.158.250:25569/api/urlshortner/get?id=${id}`).then((res) => {
            const url = res.data;
            console.log(`Redirecting to ${url}`);
            window.location.href=url;
        })
        

    }, [id])

  return (
    <div></div>
  )
}

export default RedirectPage