import React from 'react'
import { Box, Typography } from '@mui/material'

const HomePage = () => {
  return (
    <div>
    <center>HomePage</center>
    </div>
  )
}

export default HomePage