import React, { useState, useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import { Link } from 'react-router-dom';
import '../index.css'

import DensityMediumIcon from '@mui/icons-material/DensityMedium';
import SegmentIcon from '@mui/icons-material/Segment';
import HouseIcon from '@mui/icons-material/House';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';

import { getStatus } from '../utils/getStatus';

const SideBar = () => {

  const [user, setUser] = useState([])

  useEffect(() => {

    getStatus()
    .then((res) => {
      if(res.discordId) {
        setUser(res);
    };
    })
         
  }, [])
  const [open, setOpen] = useState(false)  

  return (
    <Box sx={{ backgroundColor: 'darkblue', minHeight: '100vh', width: `${open ? '200px' : '70px'}` }} id="sidebar">
      <Box sx={{ paddingLeft: `${open ? '150px' : '20px'}`, paddingTop: '20px' }}>
        <Box>
        <DensityMediumIcon sx={{ color: '#fff', display: `${open ? 'none' : ''}`}} id="menu-button" onClick={() => { if(open === false) { setOpen(true) } else { setOpen(false) } }}/>
        <SegmentIcon sx={{ color: '#fff', display: `${open ? '' : 'none'}`}} id="menu-button" onClick={() => { if(open === false) { setOpen(true) } else { setOpen(false) } }}/>
        </Box>
      </Box>

      <Link to="/">
      <Box sx={{ paddingLeft: '20px', paddingTop: '50px' }}>
        <Box sx={{ display: 'flex' }} id="sidebar-button">
          <HouseIcon sx={{ color: '#fff' }}/> <Typography style={{ color: '#fff', display: `${open ? '' : 'none'}`, paddingLeft: '20px' }}>Home</Typography>
        </Box>
      </Box>
      </Link>

      <Link to="/projects/urlshortner">
      <Box sx={{ paddingLeft: '20px', paddingTop: '50px' }}>
        <Box sx={{ display: 'flex' }} id="sidebar-button">
          <AttachFileIcon sx={{ color: '#fff' }}/> <Typography sx={{ color: '#fff', display: `${open ? '' : 'none'}`, paddingLeft: '20px' }}>URL Shortner</Typography>
        </Box>
      </Box>
      </Link>

      <a href={user.discordId ? 'http://142.132.158.250:25569/auth/logout' : 'http://142.132.158.250:25569/auth'}>
      <Box sx={{ paddingLeft: '20px', paddingTop: { xs: '60vh', md: '70vh', lg: '75vh'}, overflow: 'hidden' }}>
        <Box sx={{ display: 'flex' }} id="sidebar-button">
          <LoginIcon sx={{ color: '#fff', display: `${user.discordId ? 'none' : ''}`} }/><Typography sx={{ color: '#fff', display: `${user.discordId ? 'none' : ''}`, paddingLeft: '25px' }}>LOGIN</Typography>
          <LogoutIcon sx={{ color: '#fff', display: `${user.discordId ? '' : 'none'}`} }/><Typography sx={{ color: '#fff', display: `${user.discordId ? '' : 'none'}`, paddingLeft: '25px' }}>LOGOUT</Typography>
        </Box>
      </Box>
      </a>


    </Box>
  )
}

export default SideBar  