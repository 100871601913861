import axios from 'axios';

const BASE_URL = 'http://142.132.158.250:25569/auth/status'

  export const getStatus = async () => {

    const { data } = await axios.get(`${BASE_URL}`, {
        withCredentials: true
    });

    return data;

  }