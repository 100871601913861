import React, { useEffect, useState } from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { Box } from '@mui/material'
import axios from 'axios';

import { Dashboard, HomePage, UrlShortner, RedirectPage } from './pages';
import { SideBar } from './components';
import { getStatus } from './utils/getStatus';

const App = () => {

    const [user, setUser] = useState([])

    useEffect(() => {

      getStatus()
      .then((res) => {
        if(res.discordId) {
          setUser(res);
      };
      })
           
    }, []) 
    
    return (
      <BrowserRouter>
      <Box>
          <SideBar />
          <Routes>
          <Route path="/" exact element={<HomePage />}/>
          <Route path="/url/:id" exact element={<RedirectPage />}/>
          <Route path="/projects/urlshortner" exact element={<UrlShortner user={user} />}/>
          </Routes>
          {user.discordId ? (
            <Routes>
              <Route path="/dashboard" exact element={<Dashboard />}/>
            </Routes>
          ) : (
            <Routes>
            </Routes>
          )}
      </Box>
  
      </BrowserRouter>
    )
 
}

export default App