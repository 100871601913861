import React, { useState } from 'react'
import { Box, Button } from '@mui/material'
import axios from 'axios';


const UrlShortner = (user) => {

  const [link, setLink] = useState('');

  const handleSubmit = async(e) => {
    e.preventDefault();
    const blog = { link };

    const { data } = await axios.post(`http://142.132.158.250:25569/api/urlshortner/post?url=${link}&discordId=123`)
    const showLink = document.getElementById('link');
    showLink.textContent = `${data}`
  }
  
  return (
        <div>
            <Box sx={{ display: 'flex',justifyContent: 'center', alignItems: 'center', height: '100vh' }}> 
            <Box sx={{ backgroundColor: '#fff', width: '500px', height: '100px'}}>
                <Box sx={{ display: 'flex',justifyContent: 'center', alignItems: 'center', height: '100%', boxShadow: ' 0 8px 16px 0 rgba(0,0,0,0.1)' }}>
                <form onSubmit={handleSubmit}>
                <input type="url" required onChange={(e) => setLink(e.target.value)} />
                <Button variant="outlined" sx={{ 
                  height: '32px', 
                  width: '90px', 
                  border: '1px solid black', 
                  borderRadius: '0', 
                  color: 'black' }}
                  type="submit">
                  Shortern
                  </Button>
                </form>
                </Box>
                <center><h4 id="link"></h4></center>
            </Box>
        </Box>
        </div>    
  )
}

export default UrlShortner